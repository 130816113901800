import React from "react";
import { css } from "glamor";
import { glow } from "../../libs/styles";

const borderWidth = "2px";
const borderRadius = "10%";
const buttonStyles = {
  borderWidth,
  borderRadius: borderRadius,
  outline: "none",
  boxShadow: "0px 7px 10px 0px #171717",
  background:
    "linear-gradient(to bottom, #4a4a4ac2 5%, #29292980 100%)",

  position: "relative",
  ":hover": {
    background:
      "linear-gradient(to bottom, #292929 5%, #4a4a4a 100%)",
    backgroundColor: "#292929",
  },
};

const buttonWrapperStyle = {
  margin: 0,
  padding: 0,
  width: "100%",
  height: "100%",
  borderRadius,
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const playStyle = {};

const stopStyle = {
  width: "100%",
  height: "100%",
};

const recordStyle = {};

interface PropType {
  isPlaying: boolean;
  onToggle: () => void;
  width?: number;
  height?: number;
  record?: boolean;
}
const MidiMediaButton: React.FC<PropType> = ({
  isPlaying,
  onToggle,
  width = 34,
  height = 34,
  record = false,
}) => {
  return (
    <button
      {...css(buttonStyles, {
        width: `${width}px`,
        height: `${height}px`,
        ...glow("white", "red"),
      })}
      onClick={() => onToggle()}
    >
      <div {...css(buttonWrapperStyle)}>
        {isPlaying && <div {...css(stopStyle)} />}
        {!isPlaying && !record && <div {...css(playStyle)} />}
        {!isPlaying && record && <div {...css(recordStyle)} />}
      </div>
    </button>
  );
};
export default MidiMediaButton;
