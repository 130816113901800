import React from "react";
import { css } from "glamor";

const buttonStyles = {
  display: "inline-block",
  overflow: "hidden",
  outline: 0,
  borderRadius: 0,
  background: "transparent",
  border: 0,
  cursor: "grabbing",
};

const buttonWrapperStyle = {
  position: "relative",
  margin: "0px auto",
  width: "100%",
  height: "100%",
  borderRadius: "50%",
  background:
    "linear-gradient(30deg, transparent 40%, rgba(42, 41, 40, .85) 40%) no-repeat 100% 0, linear-gradient(60deg, rgba(42, 41, 40, .85) 60%, transparent 60%) no-repeat 0 100%, repeating-radial-gradient(#2a2928, #2a2928 4px, #ada9a0 5px, #2a2928 6px)",
  backgroundSize: "50% 100%, 100% 50%, 100% 100%",
  ":after": {
    position: "absolute",
    border: "solid 1px #d9a388",
    borderRadius: "50%",
    boxShadow: "0 0 0 4px #da5b33, inset 0 0 0 27px #da5b33",
    background: "#b5ac9a",
    content: "''",
  },
};

const playStyle = {};

const stopStyle = {
  width: "100%",
  height: "100%",
};

const recordStyle = {};

interface PropType {
  isPlaying: boolean;
  onToggle: () => void;
  width?: number;
  height?: number;
  record?: boolean;
}
const VinylMediaButton: React.FC<PropType> = ({
  isPlaying,
  onToggle,
  width = 34,
  height = 34,
  record = false,
}) => {
  return (
    <button
      {...css(buttonStyles, {
        width: `${width}px`,
        height: `${height}px`,
      })}
      onClick={() => onToggle()}
    >
      <div
        {...css(buttonWrapperStyle, {
          ":after": {
            width: `${width / 3}px`,
            height: `${height / 3}px`,
            top: "33%",
            left: "31%",
          },
        })}
      >
        {isPlaying && <div {...css(stopStyle)} />}
        {!isPlaying && !record && <div {...css(playStyle)} />}
        {!isPlaying && record && <div {...css(recordStyle)} />}
      </div>
    </button>
  );
};
export default VinylMediaButton;
