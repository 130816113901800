import * as React from "react";
import { css, keyframes } from "glamor";
import { defaultStyles } from "../libs/styles";
import { OutboundLink } from "gatsby-plugin-gtag";
import StudioWallImage from "../assets/images/studiowall.jpg";
const FADE_TIME = 500;

const fadeIn = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 },
});

const fadeOut = keyframes({
  "0%": { opacity: 1 },
  "100%": { opacity: 0 },
});

interface PropType {
  children: any;
}

const BoothLayout: React.FC<PropType> = ({ children }) => {
  return (
    <div
      {...css({
        position: "fixed",
        width: "100%",
        top: 0,
        bottom: 0,
        overflowY: "scroll",
        overflowX: "hidden",
        scrollBehavior: "smooth",

        background: `linear-gradient(
          rgba(1, 0, 0, 0.45), 
          rgba(0, 0, 0, 0.9)
        ), url(${StudioWallImage})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        zIndex: 100,
        left: 0,
        height: "100vh",
        padding: "24px",
        animation: `${fadeIn} ease ${FADE_TIME}ms`,
      })}
    >
      <div
        {...css({
          position: "fixed",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "black",
          width: "100%",
          top: 0,
          left: 0,
          flexWrap: "wrap",
        })}
      >
        <OutboundLink
          href="/#about-me"
          title="UI Engineer in Los Angeles"
        >
          <span
            {...css(defaultStyles.font.body, {
              fontWeight: "bold",
              fontSize: "10px",
            })}
          >
            stephanimoroni.com
          </span>
        </OutboundLink>
        <div
          {...css({
            display: "flex",
            alignItems: "center",
            gridGap: "12px",
            justifyContent: "center",
            flexWrap: "wrap",
          })}
        >
          <OutboundLink
            href="/booth/recording"
            title="Play Recording Studio"
          >
            Recording
          </OutboundLink>
          <OutboundLink
            href="/booth/music"
            title="Play Virtual Music Studio"
          >
            Music
          </OutboundLink>
        </div>
      </div>
      {children}
    </div>
  );
};

export default BoothLayout;
