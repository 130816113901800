import React from "react";
import { observer } from "mobx-react";
import { Box } from "@material-ui/core";
import AppStore from "../../store/AppStore";
import MemoPlayer from "../MemoPlayer";
import { ScrollableView } from "../../lib/rio/ScrollableView";
import Player from "../../lib/audiox/models/Player";
import VinylMediaButton from "../../lib/components/VinylMediaButton";
import MidiMediaButton from "../../lib/components/MidiMediaButton";
// import bram1 from "../../lib/audiox/samples/instruments/bram1.wav";
// import bram2 from "../../lib/audiox/samples/instruments/bram2.wav";
// import bram3 from "../../lib/audiox/samples/instruments/bram3.wav";
// import bram4 from "../../lib/audiox/samples/instruments/bram4.mp3";
// import djscratch1 from "../../lib/audiox/samples/instruments/djscratch-1.wav";
// import djscratch2 from "../../lib/audiox/samples/instruments/djscratch-2.wav";
// import djscratch3 from "../../lib/audiox/samples/instruments/djscratch-3.wav";
// import djscratch4 from "../../lib/audiox/samples/instruments/djscratch-4.wav";
// import tension1 from "../../lib/audiox/samples/drums/tom1.wav";
// import tension2 from "../../lib/audiox/samples/drums/tom2.wav";
// import tension3 from "../../lib/audiox/samples/drums/tom3.wav";
// import tension4 from "../../lib/audiox/samples/drums/tom4.wav";
// // import tension1 from "../../lib/audiox/samples/instruments/tension1.wav";
// // import tension2 from "../../lib/audiox/samples/instruments/tension2.wav";
// // import tension3 from "../../lib/audiox/samples/instruments/tension3.wav";
// // import tension4 from "../../lib/audiox/samples/instruments/tension4.wav";
// import tension5 from "../../lib/audiox/samples/instruments/tension5.wav";
// import tension6 from "../../lib/audiox/samples/instruments/tension6.wav";
// import tension7 from "../../lib/audiox/samples/instruments/tension7.wav";
// import tension8 from "../../lib/audiox/samples/instruments/tension8.wav";
// import tension9 from "../../lib/audiox/samples/instruments/tension9.wav";
// import tension10 from "../../lib/audiox/samples/instruments/tension10.wav";
// import tension11 from "../../lib/audiox/samples/instruments/tension11.wav";
// import tension12 from "../../lib/audiox/samples/instruments/tension12.mp3";
import { MicrophoneOption } from "../../lib/audiox/models/Microphone";

const instruments: string[] = [
  // djscratch1,
  // djscratch2,
  // djscratch3,
  // djscratch4,
  // bram1,
  // bram2,
  // bram3,
  // bram4,
  // tension1,
  // tension2,
  // tension3,
  // tension4,
  // tension5,
  // tension6,
  // tension7,
  // tension8,
  // tension9,
  // tension10,
  // tension11,
  // tension12,
];
const players = instruments.map(() => new Player());
interface StudioType {
  store: AppStore;
}
const MusicStudio: React.FC<StudioType> = observer(({ store }) => {
  const booth = store.recordingBooth;
  const [liveFeedback, setLiveFeedback] = React.useState(false);
  const options = booth.microphone.microphoneOptions.map(
    (option: MicrophoneOption) => ({
      value: option.value,
      label: option.label,
      action: () => {
        booth.microphone.onMicrophoneChange(option);
      },
    }),
  );
  return (
    <ScrollableView id="recording-booth" topOffset={100}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
      >
        <Box
          marginTop="40px"
          display="flex"
          alignItems="center"
          gridGap="12px"
          flexWrap="wrap"
        >
          {players.slice(0, 4).map((player, i) => {
            return (
              <VinylMediaButton
                key={`player-${i}`}
                onToggle={async () => {
                  const filepath = instruments[i];
                  await player.loadMedia(filepath);
                  player.play();
                }}
                width={100}
                height={90}
                isPlaying={player.isPlaying}
              />
            );
          })}
        </Box>
        <Box
          marginTop="40px"
          display="flex"
          alignItems="center"
          gridGap="12px"
          flexWrap="wrap"
        >
          {players.slice(4, 16).map((player, i) => {
            return (
              <MidiMediaButton
                key={`player-${i}`}
                onToggle={async () => {
                  const filepath = instruments[i + 4];
                  await player.loadMedia(filepath);
                  player.play();
                }}
                width={100}
                height={90}
                isPlaying={player.isPlaying}
              />
            );
          })}
        </Box>
        <Box marginTop="40px">
          {booth.currentVoiceMemos.map((voiceMemo: any, i: any) => {
            const player: Player = voiceMemo.player;

            return <MemoPlayer key={`memo-${i}`} player={player} />;
          })}
        </Box>
      </Box>
    </ScrollableView>
  );
});
export default MusicStudio;
